<template>
  <v-card class="elevation-1">
    <v-card-title>
      Rebates
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- Sales Date -->
          <v-dialog
            ref="dialog"
            v-model="modalSalesDate"
            :return-value.sync="salesDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="salesDate"
                label="Sales Date"
                outlined
                dense
                placeholder="Sales Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="salesDate"
              type="month"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalSalesDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalSalesDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            class="mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.rebate`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.rebate) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFilterOutline, mdiCalendarOutline } from '@mdi/js'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'

export default {
  setup() {
    const search = ref('')
    const headers = ref([
      { text: 'Date Purchased', align: 'start', value: 'created_at' },
      { text: 'Reference Number', align: 'start', value: 'reference_number' },
      { text: 'Buyer Type', align: 'start', value: 'buyer_type' },
      { text: 'Buyer / Member Name', align: 'start', value: 'recipient' },
      { text: 'Product Code', align: 'end', value: 'product_code' },
      { text: 'Product Description', align: 'end', value: 'product_name' },
      { text: 'Quantity', align: 'end', value: 'quantity' },
      { text: 'Rebate', align: 'end', value: 'rebate' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      filter: mdiFilterOutline,
      calendar: mdiCalendarOutline,
    }

    const modalSalesDate = ref(false)
    const salesDateValue = ''
    const salesDate = ref('')

    const filterValue = ref('')

    const listUri = `${process.env.VUE_APP_URI}/api/stockist/rebates`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
    })

    const filter = async () => {
      if (salesDate.value !== '') {
        filterValue.value = fnx.base64_encode({ salesDate: salesDate.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Sales Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      salesDate.value = ''
      items.value = []
      totalItems.value = 0
    }

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      icon,

      salesDateValue,
      salesDate,
      modalSalesDate,

      filter,
      resetFilter,
    }
  },

  components: {
    snackbar,
  },
}
</script>
